import React from 'react';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import { TransitionNavigation } from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { graphql } from "gatsby";
import { Content } from "../components/body/Body";
import { Helmet } from "react-helmet";

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {layout: {eq: "feature"}}}) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            color
            tags
            draft
            description
            image {
              publicURL
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

export interface FeaturesProps {
  data: {
    header: {
      childImageSharp: {
        fixed: any;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: any;
      }>;
    };
  };
}

const FeatureFeed = css`
  padding: 40px 0 5vw;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

export const FeatureCardStyles = css`
  margin: auto;
  min-height: 220px;
  background-size: cover;

  padding: 22px;
  border-radius: 6px;
  background: #fff;
  color: #000;

  height: 100%;
  width: 100%;

  text-align: center;

  :hover, :focus {
    box-shadow: 0 4px 13px rgb(0, 0, 0, .1);
    text-decoration: none;
    color: #000;
  }
  :active {
    box-shadow: 0 4px 13px rgb(0, 0, 0, .15);
  }

  img {
    width: 100px;
    height: 100px;
  }

  h4 {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
  }
`;

const Integrations: React.FC<FeaturesProps> = props => {
  const features = props.data.allMarkdownRemark.edges.sort((a, b) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title));

  return (
    <IndexLayout>
      <Wrapper>
        <Helmet>
          <title>FastApps Features</title>
        </Helmet>
        <TransitionNavigation mode="normal" />
        <Content>
          <div css={FeatureFeed}>
            {features.map((feature) => {
              return ((feature.node.frontmatter.draft !== true || process.env.NODE_ENV !== 'production') && (
                <a key={feature.node.frontmatter.title} href={feature.node.fields.slug} css={FeatureCardStyles} className="post-card">
                  <article>
                    <img
                      alt={`${feature.node.frontmatter.title} cover image`}
                      src={feature.node.frontmatter.image.publicURL}
                    />
                    <h4 style={{ color: feature.node.frontmatter.color }}>
                      {feature.node.frontmatter.title}
                    </h4>
                    <p>
                      {feature.node.frontmatter.description}
                    </p>
                  </article>
                </a>
              ));
            })}
          </div>
          <div style={{ textAlign: "center" }}>
            Not seeing the integration you’re looking for? <a href="mailto:support@fastapps.io?subject=Integration%20Idea!&body=Please%20describe%20your%20integration%20idea" target="_blank">Submit your integration idea</a>
          </div>
        </Content>
        {props.children}
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Integrations;
